@media (min-width: 992px) {
  .page_wrapper {
    width: calc(100% - 300px);
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}
.backdropOpen {
  display: block;
}
