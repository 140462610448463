/* .activeLink {
  text-decoration: underline;
  background: rgba(255, 255, 255, 0.15);
} */
.dropdown {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  /* transition: height 0.4s ease-in-out; */
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
}
.dropdown.show {
  opacity: 1;
  max-height: 99em;
  transition: max-height 0.3s ease-in-out;
}
