.kep-login-facebook2 {
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  border-radius: 10px !important;
  border: 1px solid transparent;
  font-size: 12px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  margin-left: 10px;
  /* padding-bottom: 2px; */
  padding: 5px;
}

.kep-login-facebook2 svg {
  /* margin-right: 10px;
  padding: 10px; */
  border-radius: 2px;
  height: 58px;
  width: 41px;
  color: #3b5998;
  background-image: linear-gradient(
    to bottom,
    transparent 20%,
    white 20%,
    white 93%,
    transparent 93%
  );
  background-size: 55%;
  background-position: 70% 0;
  background-repeat: no-repeat;
}

/* restaurant partner style starts */
.timeline {
  position: relative;
  margin: 10px 0;
  padding: 0;
  list-style: none;
  counter-reset: section;
}
/* .timeline:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #e64a19;
  left: 32px;
  margin: 0;
  border-radius: 2px;
} */
.timeline li {
  position: relative;
  margin-right: 10px;
  padding-top: 14px;
  box-sizing: border-box;
  padding-left: 90px;
  min-height: 90px;
}
.timeline li:before,
.timeline li:after {
  display: block;
}

.timeline li:before {
  counter-increment: section;
  content: counter(section);
  background: #e64a19;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  border-radius: 50%;
  left: -1px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  box-sizing: border-box;
}

.timeline li::after {
  position: absolute;
  content: "";
  top: 10px;
  left: 20px;
  height: 100%;
  width: 5px;
  z-index: -1;
  border-top: transparent;
  border-left: transparent;
  border-bottom: transparent;
  border-right: 1px dashed #e64a19;
  background: transparent;
}
.timeline li:last-child::after {
  content: none;
}

.timeline li:after {
  clear: both;
}

/* restaurant partner style end */

@media only screen and (max-width: 600px) {
  .timeline li:before,
  .timeline li:after {
    display: none;
  }
  .timeline li {
    padding-left: 0%;
    min-height: 0px;
  }
}
